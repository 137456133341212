<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>QR</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE REQUERIMIENTO'" :descripcion="registro.tipo.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'INSTANCIA DE RECEPCIÓN'" :descripcion="registro.instancia.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" v-if="registro.canal">
            <texto2 :colores="colores" :titulo="'CANAL/PUNTO DE RECEPCIÓN'" :descripcion="registro.canal.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="registro.admision" class="pr-1">
            <texto2 :colores="colores" :titulo="'ADMISIÓN'" :descripcion="registro.admision.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6" v-if="registro.entecontrol">
            <texto2 :colores="colores" :titulo="'ENTE DE CONTROL'" :descripcion="registro.entecontrol.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'DUPLICADO'" :descripcion="registro.gesduplicado.duplicado.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6" v-if="registro.gesduplicado.duplicadomotivo != null">
            <texto2 :colores="colores" :titulo="'MOTIVO DUPLICADO'" :descripcion="registro.gesduplicado.duplicadomotivo.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6" v-if="registro.gesduplicado.codigo != 0">
            <texto2 :colores="colores" :titulo="'QR INICIAL'" :descripcion="registro.gesduplicado.codigo"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.gesduplicado != null">
          <v-col cols="12" md="6"
            v-if="registro.fechacerrado != null && marcacionID(registro.gesduplicado) !== 2">
            <texto2 :colores="colores" :titulo="'MARCACIÓN'"
              :descripcion="registro.gesduplicado.marcacion.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="1" v-if="marcacionID(registro.gesduplicado) === 3  && registro.subestado_id !== 6">
            <v-btn @click="borrarMarcacion = true" icon :color="colores.red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="registro.gesduplicado" class="pr-1">
            <texto2 :colores="colores" :titulo="'REINCIDENTE'" :descripcion="registro.gesduplicado.reincidente.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6" v-if="registro.gesduplicado.canaoriginol != null">
            <texto2 :colores="colores" :titulo="'CANAL QUE ORIGINÓ QR'" :descripcion="registro.gesduplicado.canaoriginol.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'MOTIVO PRINCIPAL'" :descripcion="registro.motivo.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.motivo">
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'MOTIVO'" :descripcion="registro.submotivo.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row v-if="registro.subestado.id !== 6 && session.area != 16 && this.session.id === this.idEscalado">
          <v-col cols="12">
            <v-checkbox
            v-model="itemsLectura.editarMotivo"
            :label="`Modificar Motivo`"
            ></v-checkbox>
          </v-col>
        </v-row>
         <v-row no-gutters>
          <v-col cols="12" v-if="itemsLectura.editarMotivo">
            <v-autocomplete v-model="itemsLectura.objMotivoQR"
            v-on:input="input_motivo(itemsLectura.objMotivoQR)" return-object  :items="itemsLectura.motivosPrincipales" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO PRINCIPAL *"></v-autocomplete>
          </v-col>
        </v-row>
             <v-row no-gutters>
          <v-col cols="12" v-if="itemsLectura.editarMotivo">
            <v-autocomplete v-model="itemsLectura.objSubMotivoQR" v-on:input="input_submotivo(itemsLectura.objSubMotivoQR)"  return-object :items="itemsLectura.submotivo" item-text="descripcion" item-value="id" :color="colores.primario" label="MOTIVO *"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.submotivo.opcion1">
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'DESCRIPCIÓN MOTIVO'" :descripcion="registro.submotivo.opcion1"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="registro.quejaexpres">
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'QUEJA EXPRÉS'" :descripcion="registro.quejaexpres.descripcion"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text style="white-space:pre-wrap;">
                <div >{{ registro.descripcion }}</div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
          <br>
          <v-row no-gutters v-if="(session.area === 16 && registro.gescierrefiles.length > 0)">
            <v-col cols="12" md="6">
              <b>ARCHIVOS DE RESPUESTA FINAL</b>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="(session.area === 16 && registro.gescierrefiles.length > 0)">
            <v-col cols="12" md="6">
              <div v-for="(itemFile, i) in registro.gescierrefiles" :key="i">
                <verArchivos v-if="itemFile.tipo_id===1 && itemFile.replica_id === null" :colores="colores" :tipo="'CIERREFINAL'" :item="itemFile"></verArchivos>
              </div>
            </v-col>
          </v-row>
        <v-list dense>
            <div v-for="(item, index) in registro.gesarchivos" :key="index">
                <verArchivosDate :colores="colores" :tipo="'GESTION'" :item="item"></verArchivosDate>
                <v-divider></v-divider>
            </div>
        </v-list>

        <v-row no-gutters v-if="registro.aplicativo.descripcion === 'CORREO'">
          <v-col cols="12" class="text-left">
            <v-btn @click="verCorreo">VER CORREO</v-btn>
          </v-col>
        </v-row>
        <dialgoVerCorreo :dialogo="dialogoCorreo" :colores="colores" :id_proyecto="id_proyecto" :id_correo="id_correo" :tokenCorreo="tokenCorreo" @closed_correo="cerrarVentaCorreo"></dialgoVerCorreo>
        <div v-if="dataReplica.length > 0">
          <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>ARGUMENTO RÉPLICA</b></span></v-toolbar>
          <v-row no-gutters v-for="(itemReplica, indexEsc) in dataReplica" :key="indexEsc">
            <v-col cols="12" md="" class="pr-1">
              <span :style="[{'color': colores.primario}, {'font-size': 10 }]">{{itemReplica.created_at.substr(0,
                10)}}</span>
              <v-card-text style="white-space:pre-wrap;" class="pl-0 pt-0">
                <span>{{ itemReplica.argumento_replica }}</span>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-sheet>
<v-layout row justify-center>
  <v-dialog v-model="updated" persistent max-width="450">
  <v-card>
          <v-toolbar class="headline" color="green darken-2" dark>ESTADO</v-toolbar>
          <v-card-text>
            <br>
            <v-layout row>
              <v-flex xs12 class="pt-5">
                <center>
                  <v-icon dark color="green darken-2" size="30">mdi-check-circle</v-icon> DATOS ACTUALIZADOS
                </center>
                <br>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" dark @click="updated=false">ACEPTAR</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</v-layout>
<v-layout row justify-center>
  <v-dialog v-model="esperar" persistent max-width="450">
    <v-card :color="colores.primario">
        <v-card-text class="pt-4">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card-text>
    </v-card>
  </v-dialog>
</v-layout>
<v-dialog v-model="borrarMarcacion" persistent max-width="450">
    <v-card>
      <v-toolbar class="headline" :color="colores.primario" dark>MARCACIÓN</v-toolbar>
      <v-card-text>
        <br>
        ¿DESEA ELIMINAR LA MARCACIÓN?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" dark @click="eliminar(registro)">ACEPTAR</v-btn>
        <v-btn color="grey darken-1" dark @click="borrarMarcacion = false">CANCELAR</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
// import verArchivos from '@/components/widgets/ver_archivosComponent.vue'
import recListado from '@/js/rec_listado.js'
import recGestiones from '@/js/rec_gestiones.js'
export default {
  name: 'crm_lectura_clienteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    verArchivos: () => import('@/components/widgets/ver_archivosComponent.vue'),
    verArchivosDate: () => import('@/components/widgets/ver_archivosdateComponent.vue'),
    dialgoVerCorreo: () => import('@/components/correos/dialogo_verComponent.vue')
  },
  mixins: [recListado, recGestiones],
  props: ['colores', 'registro', 'session', 'dataReplica'],
  data: () => ({
    updated: false,
    esperar: false,
    idEscalado: '',
    itemsLectura: { motivosPrincipales: [], submotivo: [], objMotivoQR: [], objSubMotivoQR: [], editarMotivo: false },
    dialogoCorreo: false,
    tokenCorreo: 0,
    id_proyecto: 0,
    id_correo: 0,
    borrarMarcacion: false
  }),
  created () {
    this.rec_listado_motivo(this.registro.tipo.secundario_id)
    this.startInterval()
  },
  methods: {
    input_motivo (pMotivo) {
      this.rec_listado_submotivo(pMotivo.id)
    },
    input_submotivo () {
      this.esperar = true
      this.rec_gestion_update_motivo()
    },
    startInterval () {
      this.idSetIntervalQR = setInterval(() => {
        // 10000 - 10 segundos
        // 60000 - 60 segundos
        // 120000 - 2 minutos
        // 240000 - 4 minutos
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if (this.registro.escalamientos[i].fecha_cerrado === null) {
            this.idEscalado = this.registro.escalamientos[i].id_escaladoa
          }
        }
        this.rec_gestion_noreload_por_id(this.gestion_id)
      }, 10000)
    },
    verCorreo () {
      this.id_proyecto = this.registro.gescorreo.proyecto_id
      this.id_correo = this.registro.gescorreo.correo_id
      this.tokenCorreo = this.tokenCorreo + 1
      this.dialogoCorreo = true
    },
    cerrarVentaCorreo () {
      this.dialogoCorreo = false
    },
    marcacionID (gesduplicado) {
      let retorno = 0
      if (gesduplicado !== null) {
        if (gesduplicado.marcacion_id !== 488) {
          retorno = 3
        }
        if (gesduplicado.marcacion_id === null) {
          retorno = 2
        }
      } else {
        retorno = 1
      }
      return retorno
    },
    eliminar (registro) {
      this.esperar = true
      this.eliminarMarcacionJS(registro.id)
    }
  }

}
</script>
